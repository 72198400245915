import { createSelector } from '@ngrx/store';
import { getClassroomsState, ClassroomsState } from '..';
import { ClassroomParticipantsState } from './participants.reducer';

export const getClassroomParticipantsState = createSelector(
  getClassroomsState,
  (state: ClassroomsState) => state.participants,
);

export const getClassroomParticipants = createSelector(
  getClassroomParticipantsState,
  (state: ClassroomParticipantsState) => state.items,
);

export const getClassroomParticipantsDetails = createSelector(
  getClassroomParticipantsState,
  (state: ClassroomParticipantsState) => state.details,
);

export const getClassroomParticipantsFilter = createSelector(
  getClassroomParticipantsState,
  (state: ClassroomParticipantsState) => state.filter,
);

export const getClassroomParticipantsPagination = createSelector(
  getClassroomParticipantsState,
  (state: ClassroomParticipantsState) => state.pagination,
);

export const getClassroomParticipantsLoading = createSelector(
  getClassroomParticipantsState,
  (state: ClassroomParticipantsState) => state.loading,
);

export const getClassroomParticipantsLoaded = createSelector(
  getClassroomParticipantsState,
  (state: ClassroomParticipantsState) => state.loaded,
);

export const getClassroomParticipantsIsNotEmpty = createSelector(
  getClassroomParticipantsState,
  (state: ClassroomParticipantsState) => state.items && !!state.items.length,
);
