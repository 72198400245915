import { Injectable } from '@angular/core';
import { ClassroomsService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Classroom } from 'lingo2-models';

import { of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';

import { getClassroomDetails } from './classroom.selectors';
import { loadClassroomBySlug, loadClassroomBySlugSuccess, requestFail } from './classroom.actions';

@Injectable()
export class ClassroomEffects {
  constructor(private actions$: Actions, private classroomService: ClassroomsService, private store: Store) {}

  loadClassroom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadClassroomBySlug),
      withLatestFrom(this.store.select(getClassroomDetails)),
      switchMap(([{ slug }, details]) =>
        this.classroomService.getClassroomBySlug(slug, details).pipe(
          map((classroom: Classroom) => loadClassroomBySlugSuccess({ classroom })),
          catchError((error: Error) => of(requestFail({ error: error.message }))),
        ),
      ),
    ),
  );
}
