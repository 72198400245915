import { Injectable } from '@angular/core';
import { ClassroomsService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom, filter } from 'rxjs/operators';

import { getClassroom } from '../classroom/classroom.selectors';
import {
  loadClassroomParticipants,
  loadClassroomParticipantsSuccess,
  loadNextPageClassroomParticipants,
  requestFail,
} from './participants.actions';
import {
  getClassroomParticipantsDetails,
  getClassroomParticipantsFilter,
  getClassroomParticipantsPagination,
} from './participants.selectors';

@Injectable()
export class ClassroomParticipantsEffects {
  constructor(private actions$: Actions, private classroomsService: ClassroomsService, private store: Store) {}

  loadClassroomParticipants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadClassroomParticipants),
      switchMap(() => this.store.select(getClassroom)),
      filter((x) => !!x),
      map((x) => x.id),
      withLatestFrom(this.store.select(getClassroomParticipantsDetails)),
      withLatestFrom(this.store.select(getClassroomParticipantsFilter)),
      withLatestFrom(this.store.select(getClassroomParticipantsPagination)),
      switchMap(([[[classroom_id, _details], _filter], _pagination]) =>
        this.classroomsService.getClassroomParticipants(classroom_id, _pagination, _details, _filter).pipe(
          map((response) => loadClassroomParticipantsSuccess({ response })),
          catchError((error: Error) => of(requestFail({ error: error.message }))),
        ),
      ),
    ),
  );

  loadNextPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNextPageClassroomParticipants),
      switchMap(() => [loadClassroomParticipants()]),
    ),
  );
}
