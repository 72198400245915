import { IContentBlock } from '@core/services/interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import { Meeting, IFindMeetingsFilter, MeetingDetailsType } from 'lingo2-models';
import { clearClassroomMeetings, loadClassroomMeetings, loadClassroomMeetingsSuccess, loadNextPageClassroomMeetings, pushClassroomMeeting, requestFail } from './meetings.actions';

export type MeetingsBlock = IContentBlock<Meeting, IFindMeetingsFilter>;

export interface MeetingsState extends MeetingsBlock {
  error: string;
  details: MeetingDetailsType[];
  infiniteScroll: boolean;
}

const PAGE_SIZE = 100;

const initialState: MeetingsState = {
  items: [],
  loading: false,
  loaded: false,
  filter: {
    content_type: [],
  },
  pagination: {
    page: 1,
    pageSize: PAGE_SIZE,
    total: 0,
    totalPages: 0,
  },
  error: null,
  details: null,
  infiniteScroll: true,
};

const contentReducer = createReducer(
  initialState,

  on(requestFail, (state, { error }) => ({ ...state, loading: false, error })),

  on(loadClassroomMeetings, (state) => ({ ...state, loading: true, error: null })),

  on(loadClassroomMeetingsSuccess, (state, { response }) => {
    const uniqueItems = response.results.filter((x) => !state.items.some((item) => item.id === x.id));
    return {
      ...state,
      items: state.infiniteScroll ? [...state.items, ...uniqueItems] : [...uniqueItems],
      pagination: {
        ...state.pagination,
        total: response.total,
        totalPages: response.totalPages,
        page: response.page,
      },
      loading: false,
      loaded: true,
    };
  }),

  on(clearClassroomMeetings, (state) => ({ ...initialState })),

  on(loadNextPageClassroomMeetings, (state) => {
    const isAvailable = state.pagination.page < state.pagination.totalPages;
    const page = isAvailable ? state.pagination.page + 1 : state.pagination.page;
    return {
      ...state,
      pagination: {
        ...state.pagination,
        page,
      },
    };
  }),

  on(pushClassroomMeeting, (state, { meeting }) => {
    const isUnique = !state.items.some((item) => item.id === meeting.id);
    return {
      ...state,
      items: isUnique ? [meeting, ...state.items] : [...state.items],
    };
  }),
);

export function reducer(state = initialState, action: Action) {
  return contentReducer(state, action);
}
